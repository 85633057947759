<template>
  <div :class="contentWidth === 'boxed' ? 'container p-0' : null">
    <router-view />
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import api from '@/api'

export default {
  setup() {
    const { contentWidth } = useAppConfig()
    return { contentWidth }
  },
  beforeCreate(){
    //Запрос за данными пользователя
    const self = this;
    api.users.me().then(data=>{
      self.$store.commit('app/SET_USER', data.data.result);
    })
    .catch(err=>{
      self.$router.push('/login');
    })
  }
}
</script>
